import React from "react";
import { Container, Row, Col } from 'react-bootstrap';

export default class PickListToPrint extends React.Component {
    render() {
        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div style={{ marginTop: "70px", border: "solid", borderWidth: "2px" }}>
                                <table id="table-to-xls">
                                    <tr style={{ fontSize: "25px", fontWeight: "bold" }}>
                                        <th>Code</th>
                                        <th>Description</th>
                                        <th>Unit</th>
                                        <th>Quantity</th>
                                    </tr>
                                    <tbody style={{ border: "solid", borderWidth: "2px" }}>{this.props.PickList.map((det) => <tr>
                                        <td>{det.ProductCode}</td>
                                        <td>{det.Product}</td>
                                        <td>{det.Unit}</td>
                                        <td>{det.Quantity}</td>
                                    </tr>)}</tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr style={{ fontSize: "25px", fontWeight: "bold", border: "solid", borderWidth: "2px" }}>
                                        <th colspan="2">Total Line Count: {this.props.PickList ? this.props.PickList.length : 0}</th>
                                        <th colspan="1"></th>
                                        <th>{this.props.PickList.reduce((currentTotal, det) => {
                                            return parseInt(det.Quantity) + currentTotal
                                        }, 0)}</th>
                                    </tr>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        );
    }
}